<script>
import { required, email } from "vuelidate/lib/validators";
import { mapState } from 'vuex'
import {
  authEasyMethods,
  notificationMethods
} from "@/state/helpers";

export default {
  data() {
    return {
      email: "contato@sidsolucoes.com.br",
      password: "easy@rochanet",
      submitted: false,
      tryingToLogIn: false,
    };
  },
  computed: {
    ...mapState({ 
      notification: state => state.notification 
    })
  },
  created() {
    document.body.classList.add("auth-body-bg");
  },
  validations: {
    email: { required, email },
    password: { required }
  },
  methods: {
    ...authEasyMethods,
    ...notificationMethods,
    enter: (el, done) => {
      done();
    },
    // Try to log the user in with the username
    // and password they provided.
    async tryToLogIn() {
      this.submitted = true;
      // stop here if form is invalid
      this.$v.$touch();

      if (this.$v.$invalid) {
        return;
      } else {
        if (process.env.VUE_APP_DEFAULT_AUTH === "firebase") {
          this.tryingToLogIn = true;
          // Reset the authError if it existed.
          this.authError = null;
          return (
            await this.logIn({
              email: this.email,
              password: this.password
            })
              // eslint-disable-next-line no-unused-vars
              .then(token => {
                this.tryingToLogIn = false;
                this.isAuthError = false;
                // Redirect to the originally requested page, or to the home page
                this.$router.push(
                  this.$route.query.redirectFrom || { name: "select-company" }
                );
              })
              .finally(() => {
                this.tryingToLogIn = false;
              })
          );
        } else {
          if (process.env.VUE_APP_DEFAULT_AUTH === "easyindustria-api") {
            this.tryingToLogIn = true;
            // Reset the authError if it existed.
            this.authError = null;
            return (
              await this.loGin({ email: this.email, password: this.password })
                .then(() => {
                  this.tryingToLogIn = false;
                  this.isAuthError = false;
                })
                .finally(() => {
                  this.tryingToLogIn = false;
                  setTimeout(() => this.submitted = false, 6000);
                })
            );
          } else {
            console.log('Login via Fake');
            const { email, password } = this;
            if (email && password) {
              this.login({ email, password });
            }
          }
        }
      }
    }
  }
};
</script>

<template>
  <div>
    <div class="home-btn d-none d-sm-block">
      <a href="/">
        <i class="mdi mdi-home-variant h2 text-white"></i>
      </a>
    </div>
    <div>
      <div class="container-fluid p-0">
        <div class="row no-gutters">
          <div class="col-lg-4">
            <div class="authentication-page-content p-4 d-flex align-items-center min-vh-100">
              <div class="w-100">
                <div class="row justify-content-center">
                  <div class="col-lg-9">
                    <div>
                      <div class="text-center">
                        <div>
                          <a href="/" class="logo">
                            <img src="@/assets/images/logo.png" height="90" alt="logo" />
                          </a>
                        </div>

                        <h4 class="font-size-18 mt-4">Seja bem vindo!</h4>
                        <p class="text-muted">Faça login para acessar o EasyIndustria Web.</p>
                      </div>
                      <div>
                        <b-alert
                          variant="danger"
                          class="mt-3"
                          :show="!!notification.message"
                          fade
                        >{{notification.message}}</b-alert>
                      </div>

                      <div class="p-2 mt-5">
                        <form class="form-horizontal" @submit.prevent="tryToLogIn">
                          <div class="form-group auth-form-group-custom mb-4">
                            <i class="ri-mail-line auti-custom-input-icon"></i>
                            <label for="email">E-mail</label>
                            <input
                              type="email"
                              v-model="$v.email.$model"
                              class="form-control"
                              id="email"
                              placeholder="Enter email"
                              :class="{ 'is-invalid': $v.$invalid && $v.email.$error }"
                            />
                            <div v-if="$v.$invalid && $v.email.$error" class="invalid-feedback">
                              <span v-if="!$v.email.required">E-mail obrigatório.</span>
                              <span v-if="!$v.email.email">Digite um e-mail válido.</span>
                            </div>
                          </div>

                          <div class="form-group auth-form-group-custom mb-4">
                            <i class="ri-lock-2-line auti-custom-input-icon"></i>
                            <label for="userpassword">Senha</label>
                            <input
                              v-model="$v.password.$model"
                              type="password"
                              class="form-control"
                              id="userpassword"
                              placeholder="Enter password"
                              :class="{ 'is-invalid': $v.$invalid && $v.password.$error }"
                            />
                            <div
                              v-if="$v.$invalid && !$v.password.required"
                              class="invalid-feedback"
                            >Senha obrigatório.</div>
                          </div>

                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              class="custom-control-input"
                              id="customControlInline"
                            />
                            <label
                              class="custom-control-label"
                              for="customControlInline"
                            >Lembrar-me</label>
                          </div>

                          <transition name="fade" v-on:enter="enter">
                            <div class="mt-4 text-center">
                              <i v-if="submitted" class="fa fa-spinner fa-5x fa-spin text-success"></i>
                            </div>
                          </transition>
                          <div class="mt-4 text-center">
                            <button v-if="!submitted"
                              class="btn btn-primary w-md waves-effect waves-light"
                              type="submit"
                              :disabled="$v.$invalid"
                            >Entrar</button>
                          </div>  

                          <div class="mt-4 text-center">
                            <router-link tag="a" to="/forgot-password" class="text-muted">
                              <i class="mdi mdi-lock mr-1"></i> Esqueci minha senha?
                            </router-link>
                          </div>
                        </form>
                      </div>

                      <div class="mt-5 text-center">
                        <p>
                          © 2021 EasyIndustria Web 1.0. <a href="https://sidsolucoes.com">SiD Soluções</a>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-8">
            <div class="authentication-bg">
              <div class="bg-overlay"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 1s
  }

  .fade-enter,
  .fade-leave-to {
    opacity: 0
  }
</style>